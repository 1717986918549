.top-div{
    border-radius: 1em;
    background: linear-gradient(#313638, #44474b);
}

.App{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spacer{
    height: 7em;
}